merged
<template>
  <v-dialog v-model="show" max-width="550" persistent>
    <v-card class="cardStyle">
      <v-card-title class="titleStyle">
        <span>Comentário</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="ma-0"></v-divider>

      <v-tabs v-model="tab" center-active active-class="activeOne">
        <v-tabs-slider color="#00FF99"></v-tabs-slider>
        <v-tab id="idTabSeeComment" href="#view" style="height: 32px; align-self: center" @click="commentList = true">
          Ver comentários ({{ comentarios && comentarios.length ? comentarios.length : 0 }})
        </v-tab>
        <v-tab id="idTabAddComment" href="#add" style="height: 32px; align-self: center" @click="commentList = false">
          Adicionar comentário
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="comentarios" value="view">
          <v-card-text v-if="comentarios && comentarios.length == 0" class="cardEmptyComments">
            <strong class="text-center">Esta mensagem não possui comentários<br />a serem exibidos.</strong>
          </v-card-text>

          <v-card-text class="cardComments">
            <v-card v-for="c in comentarios" :key="c.id" :color="active ? '#ffffff' : '#f9fafb'" class="px-4">
              <div>
                <span>Comentário:</span>
                <p>
                  <strong>{{ c.comment }}</strong>
                </p>
              </div>

              <div class="senderData">
                <span>
                  <v-avatar size="40px" class="mr-1"><img :src="c.thumbnail" /></v-avatar>
                  <span>&nbsp;{{ c.author }}</span>
                </span>
                <span>{{ c.time | datetime }}</span>
              </div>
            </v-card>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-btn
              id="idBtnExport"
              class="btn-ag-secondary"
              outlined
              icon
              :disabled="comentarios && comentarios.length <= 0"
              :disable="comentarios && comentarios.length > 0"
              @click="exportar()"
            >
              <v-icon>mdi-download-outline</v-icon>Exportar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="idBtnClose" class="btn-ag-primary" @click="close">Fechar</v-btn>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item value="add">
          <v-card-text>
            <v-textarea
              id="idTAComment"
              v-model="comentario"
              maxlength="280"
              :counter="280"
              no-resize
              minlength="1"
              dense
              outlined
              placeholder="Deixe um comentário..."
            />
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer />
            <v-btn id="idBtnCancel" class="btn-ag-secondary" @click="close">Cancelar</v-btn>
            <v-btn id="idBtnSave" class="btn-ag-primary" @click="save()">Salvar</v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>
<script>
import gedService from '@/services/gedService.js';
import IntegraContadorService from '@/services/integraContadorService.js';
import { mapGetters } from 'vuex';

import { eventBus } from '../../../../main';

export default {
  name: 'ModalCommentMessage',
  props: ['comentarios', 'msgInfoComment'],

  data() {
    return {
      tab: null,
      show: false,
      user: {
        name: 'Rodrigo Celso',
        urlAvatar: '/img/avatar.a5e81f19.png',
        color: 'red',
        icon: 'mdi-account-multiple',
      },

      comentario: '',
      commentList: true,
    };
  },

  computed: {
    ...mapGetters({
      userThumb: 'user/userInfo',
    }),
  },

  created() {
    this.init();
  },

  methods: {
    async getThumbnail(id) {
      let thumbnail = '';
      if (id != null) {
        let response = await gedService.profile.get(id);
        thumbnail = `data:image/png;base64,${response.data.base64}`;

        return thumbnail;
      } else {
        return (thumbnail = require('@/assets/avatar.png'));
      }
    },

    init() {
      this.$on('open', this.open);
      this.$on('close', this.close);
    },

    open() {
      this.show = true;
    },

    save() {
      this.$emit('toggleIconBool', false);
      let params = {
        thumbnail: this.userThumb.thumbnailId,
        messageIsn: this.msgInfoComment.isn,
        messageCnpj: this.msgInfoComment.cnpj,
        comment: this.comentario,
      };

      let response = IntegraContadorService.comment.save(params);
      if (response) {
        this.comentario = '';
        this.$emit('refresh');
        this.close();
        this.showToast('Comentário adicionado com sucesso!', 'success', 78);
      } else {
        this.showToast('Erro ao adicionar comentário', 'warning', 78);
      }
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async exportar() {
      let item = {
        isn: this.msgInfoComment.isn,
        cnpj: this.msgInfoComment.cnpj,
      };
      let response = await IntegraContadorService.comment.downloadReport(item);
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `Comentário de Mensagens do Ecac - Extraído em ${date.toLocaleDateString('pt-br')}.xlsx`;
      link.click();
    },

    close() {
      this.show = false;
    },

    formatCommentDate(commentDate) {
      commentDate = commentDate.replace(/T/, '-').replace(/\.\d+/, '');
      const [year, month, day, time] = commentDate.split('-');
      return `${day}/${month}/${year} ${time}`;
    },
  },
};
</script>

<style>
.cardStyle {
  padding: 0;
  max-height: 550px;
  display: flex;
  flex-direction: column;
}
.senderData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleStyle {
  padding: 16px !important;
  font-size: 20px;
  font-weight: 700 !important;
  align-content: center;
}
.cardEmptyComments {
  display: flex;
  justify-content: center;
  background-color: #e7e7fa;
  padding: 56px;
  color: #442d96;
}
.cardComments {
  background-color: #e7e7fa;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.v-tabs-items::before {
  background-color: #00ff99 !important;
}
.v-tab.activeOne {
  background-color: #e3ffee !important;
  opacity: 1;
}

.v-card--link:focus {
  text-transform: none;
  box-shadow: none !important;
  background-color: #e3ffee !important;
}

.v-card--link:focus::before {
  background-color: #e3ffee !important;
  opacity: 0;
  text-transform: none;
  box-shadow: none !important;
}

.btn-exportar-enabled-modal {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.btn-exportar-enabled-modal :hover {
  background-color: #e3ffee !important;
  padding: 13% 20%;
  max-height: 90px;
  border-radius: 3px;
}

.btn-exportar-enabled-modal:disabled {
  background-color: #e7e7fa !important;
}

#close-button :hover {
  background-color: #e3ffee !important;
  width: 179%;
  height: 329%;
  color: var(--v-primary-base);
  border-radius: 3px;
}

.action-btn :hover {
  background-color: #e3ffee !important;
  width: 179%;
  height: 329%;
  color: var(--v-primary-base);
  border-radius: 3px;
}

#cardScroll {
  flex-grow: 1;
  overflow: auto;
}
</style>
