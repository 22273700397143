var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "550", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "cardStyle" },
        [
          _c(
            "v-card-title",
            { staticClass: "titleStyle" },
            [
              _c("span", [_vm._v("Comentário")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-tabs",
            {
              attrs: { "center-active": "", "active-class": "activeOne" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tabs-slider", { attrs: { color: "#00FF99" } }),
              _c(
                "v-tab",
                {
                  staticStyle: { height: "32px", "align-self": "center" },
                  attrs: { id: "idTabSeeComment", href: "#view" },
                  on: {
                    click: function ($event) {
                      _vm.commentList = true
                    },
                  },
                },
                [
                  _vm._v(
                    " Ver comentários (" +
                      _vm._s(
                        _vm.comentarios && _vm.comentarios.length
                          ? _vm.comentarios.length
                          : 0
                      ) +
                      ") "
                  ),
                ]
              ),
              _c(
                "v-tab",
                {
                  staticStyle: { height: "32px", "align-self": "center" },
                  attrs: { id: "idTabAddComment", href: "#add" },
                  on: {
                    click: function ($event) {
                      _vm.commentList = false
                    },
                  },
                },
                [_vm._v(" Adicionar comentário ")]
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm.comentarios
                ? _c(
                    "v-tab-item",
                    { attrs: { value: "view" } },
                    [
                      _vm.comentarios && _vm.comentarios.length == 0
                        ? _c(
                            "v-card-text",
                            { staticClass: "cardEmptyComments" },
                            [
                              _c("strong", { staticClass: "text-center" }, [
                                _vm._v("Esta mensagem não possui comentários"),
                                _c("br"),
                                _vm._v("a serem exibidos."),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-text",
                        { staticClass: "cardComments" },
                        _vm._l(_vm.comentarios, function (c) {
                          return _c(
                            "v-card",
                            {
                              key: c.id,
                              staticClass: "px-4",
                              attrs: {
                                color: _vm.active ? "#ffffff" : "#f9fafb",
                              },
                            },
                            [
                              _c("div", [
                                _c("span", [_vm._v("Comentário:")]),
                                _c("p", [
                                  _c("strong", [_vm._v(_vm._s(c.comment))]),
                                ]),
                              ]),
                              _c("div", { staticClass: "senderData" }, [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "mr-1",
                                        attrs: { size: "40px" },
                                      },
                                      [
                                        _c("img", {
                                          attrs: { src: c.thumbnail },
                                        }),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(" " + _vm._s(c.author)),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("span", [
                                  _vm._v(_vm._s(_vm._f("datetime")(c.time))),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-ag-secondary",
                              attrs: {
                                id: "idBtnExport",
                                outlined: "",
                                icon: "",
                                disabled:
                                  _vm.comentarios &&
                                  _vm.comentarios.length <= 0,
                                disable:
                                  _vm.comentarios && _vm.comentarios.length > 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportar()
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-download-outline")]),
                              _vm._v("Exportar "),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-ag-primary",
                              attrs: { id: "idBtnClose" },
                              on: { click: _vm.close },
                            },
                            [_vm._v("Fechar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tab-item",
                { attrs: { value: "add" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-textarea", {
                        attrs: {
                          id: "idTAComment",
                          maxlength: "280",
                          counter: 280,
                          "no-resize": "",
                          minlength: "1",
                          dense: "",
                          outlined: "",
                          placeholder: "Deixe um comentário...",
                        },
                        model: {
                          value: _vm.comentario,
                          callback: function ($$v) {
                            _vm.comentario = $$v
                          },
                          expression: "comentario",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-secondary",
                          attrs: { id: "idBtnCancel" },
                          on: { click: _vm.close },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-primary",
                          attrs: { id: "idBtnSave" },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("Salvar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }